<template>
    <div class="newsletter-form">
        <template v-if="submitted">
            <h5>{{ $translate('newsletter_form_success_text', 'Du har nu anmält dig till nyhetsbrevet!') }}</h5>
        </template>
        <template v-else>
            <h5>{{ $translate('newsletter_header', 'Registrera dig för vårt nyhetsbrev') }}</h5>
            <p>{{ $translate('newsletter_p', 'Få tips, erbjudanden och mycket mer direkt på mailen.') }}</p>

            <div class="form-container">
                <div
                    v-if="emailError"
                    class="form-container__error"
                >
                    {{ $translate('newsletter_form_invalid_email', 'Ogiltig email') }}
                </div>
                <input 
                    id="email"
                    v-model="email" 
                    type="email" 
                    :placeholder="$translate('newsletter_form_email_placeholder', 'Email')"
                >

                <button
                    class="btn btn-sm btn-green"
                    @click="submit"
                >
                    {{ $translate('newsletter_form_submit_text', 'Registrera dig') }}
                </button>
            </div>
            <div 
                v-if="$validateText($translate('newsletter_form_disclaimer'))"
                class="disclaimer rich-text" 
                v-html="$prismic.asHtml($translate('newsletter_form_disclaimer'))" 
            />
        </template>
    </div>
</template>
<script>
export default {
    data() {
        return {
            email: '',
            submitted: false,
            emailError: false
        };
    },
    methods: {
        submit() {

            this.emailError = false;
            
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.email.match(validEmailRegex)) {

                if (! this.$isProduction()) {
                    // eslint-disable-next-line no-console
                    console.log('submitting to broker...');
                    return;
                }
                
                this.$axios
                    .post('https://api.broker.compary.com/users', {
                        email: this.email,
                        // eslint-disable-next-line camelcase
                        guide_id: process.env.BROKER_GUIDE_ID,
                        tracking: this.$tracking.getTrackingObject(),
                        // eslint-disable-next-line camelcase
                        redirect_url: null
                    })
                    .then(() => {
                        this.submitted = true;
                    });
            }
            else {
                this.emailError = true;
            } 
        }
    }
}
</script>

<style lang="scss" scoped>
    .newsletter-form {
        max-width: 500px;
        padding: 40px 20px;
        margin: 0 auto;
        text-align: center;

        .form-container {
            margin-top: 15px;
            gap: 15px;
        }

        .disclaimer::v-deep {
            margin-top: 15px;
            & * {
                font-size: 12px;
                line-height: 1rem;

                &:not(a) {
                    color: $muted;
                    font-weight: lighter;
                }
            }
        }
    }
</style>